import React, {CSSProperties, FunctionComponent, useState} from "react";

export interface GridLocation {
    row: number;
    column: number;
}


interface GridProps {
    rows: number;
    columns: number;
    numbers: number[][] | null;
    correctAnswerEvent: (from: GridLocation, fromNumber: number, to: GridLocation, toNumber: number) => void;
    wrongAnswerEvent: () => void;
}

const boxSize = 50;
const margin = 5;

const boxStyle: CSSProperties = {
    position: 'absolute',
    border: '1px solid black',
    width: `${boxSize}px`,
    height: `${boxSize}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const clickedBoxStyle: CSSProperties = {
    ...boxStyle,
    background: 'lightpink',
}

const gridParentStyle: CSSProperties = {
    position: 'relative',
};
const NumberGrid: FunctionComponent<GridProps> = ({
                                                      rows, columns, numbers,
                                                      correctAnswerEvent, wrongAnswerEvent
                                                  }) => {
    const rowsArray = Array.from({length: rows}, (_, i) => i);
    const columnsArray = Array.from({length: columns}, (_, i) => i);
    const [selectedLocation, setSelectedLocation] = useState<GridLocation | null>(null);

    const isSelectedLocation = (location: GridLocation) => {
        return selectedLocation !== null && selectedLocation.row === location.row &&
            selectedLocation.column === location.column;
    }

    const adjacentLocation = (selectedLocation: GridLocation, location: GridLocation): boolean => {
        return (selectedLocation.column === location.column && Math.abs(selectedLocation.row - location.row) === 1) ||
            (selectedLocation.row === location.row && Math.abs(selectedLocation.column - location.column) === 1);
    }

    const locationClicked = (location: GridLocation) => {
        if (numbers === null) {
            return;
        }

        if (selectedLocation === null || !adjacentLocation(selectedLocation, location)) {
            setSelectedLocation(location);
            return;
        }

        if (!isSelectedLocation(location)) {
            const fromNumber = numbers[selectedLocation.row][selectedLocation.column];
            const toNumber = numbers[location.row][location.column];

            if (fromNumber + toNumber === 10) {
                correctAnswerEvent(selectedLocation, fromNumber, location, toNumber);
            } else {
                wrongAnswerEvent();
            }
        }

        setSelectedLocation(null);
    }

    return (
        <div style={{...gridParentStyle}}>
            {rowsArray.map((_, row) => columnsArray.map((_, column) =>
                (<div key={row * columns + column}
                      style={{
                          ...(isSelectedLocation({row, column}) ? clickedBoxStyle : boxStyle),
                          top: `${row * (boxSize + margin)}px`,
                          left: `${column * (boxSize + margin)}px`
                      }}
                      onClick={() => locationClicked({row, column})}>
                        {(numbers == null) ? "" : numbers[row][column]}
                    </div>
                )))
            }
        </div>
    );
};

export default NumberGrid;