import React from "react";

interface TimerProps {
    secondsRemaining: number | null;
}

const Timer: React.FC<TimerProps> = ({secondsRemaining}) => {
    if (secondsRemaining === null) {
        return (<></>);
    }

    const minutes = Math.floor(secondsRemaining / 60);
    const seconds = secondsRemaining % 60;

    return (<div>Time left: {minutes}:{seconds < 10 ? "0" : ""}{seconds}</div>)
};

export default Timer;