import React from "react";

interface ScoreProps {
    score: number | null;
}

const Score: React.FC<ScoreProps> = ({score}) => {
    return (<div>Score: {score ?? ""}</div>)
};

export default Score;