import React, {useEffect, useState} from 'react';
import './App.css';
import NumberGrid, {GridLocation} from "./NumberGrid";
import Score from "./Score";
import Timer from "./Timer";

function App() {
    const rows = 5;
    const columns = 5;
    const [score, setScore] = useState<number | null>(null);
    const [numbers, setNumbers] = useState<number[][] | null>(null);
    const numberPalette = [1, 2, 3, 4, 5, 5, 6, 7, 8, 9];
    const [message, setMessage] = useState("");
    const [secondsRemaining, setSecondsRemaining] = useState<number | null>(null);

    const gameOverEvent = () => {
        setSecondsRemaining(null);
        setMessage("GAME OVER");
        setNumbers(null);
    }

    useEffect(() => {
        if (secondsRemaining !== null) {
            if (secondsRemaining > 0) {
                const timerId = setInterval(() => setSecondsRemaining(secondsRemaining - 1), 1000);

                return () => clearInterval(timerId);
            } else {
                gameOverEvent();
            }
        }
    }, [secondsRemaining])

    const generateRandomNumber = () => numberPalette[Math.floor(Math.random() * numberPalette.length)];

    const newGameEvent = () => {
        let grid: number[][] = Array(rows);
        for (let i = 0; i < rows; i++) {
            grid[i] = Array(columns);
            for (let j = 0; j < columns; j++) {
                grid[i][j] = generateRandomNumber();
            }
        }
        setNumbers(_ => grid);

        setScore(0);
        setMessage("");
        setSecondsRemaining(60);
    };

    const cloneNumbersArray = (inArray: number[][]): number[][] => {
        return inArray.map((v: number[]) => [...v])
    };

    const correctAnswerEvent = (from: GridLocation, fromNumber: number, to: GridLocation, toNumber: number) => {
        const newFrom = generateRandomNumber();
        const newTo = generateRandomNumber();

        setNumbers(prevNumbers => {
            if (prevNumbers === null) {
                return null;
            }

            const newNumbers = cloneNumbersArray(prevNumbers);
            newNumbers[from.row][from.column] = newFrom;
            newNumbers[to.row][to.column] = newTo;

            return newNumbers;
        });

        setScore(s => (s ?? 0) + 1);
        setMessage(`${fromNumber}+${toNumber}=${fromNumber + toNumber} CORRECT!`)
    }

    const wrongAnswerEvent = () => {
        setMessage("WRONG!")
    }

    return (
        <div className="App">
            <button onClick={() => newGameEvent()}>New Game</button>
            <Score score={score}/>
            <Timer secondsRemaining={secondsRemaining}/>
            Find touching blocks that add up to 10!
            <h1>{message}&nbsp;</h1>
            <NumberGrid numbers={numbers} rows={rows} columns={columns} correctAnswerEvent={correctAnswerEvent}
                        wrongAnswerEvent={wrongAnswerEvent}/>
        </div>
    );
}

export default App;
